import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import LoggedInLayout from "../layout";

import BackdropLoading from "../components/BackdropLoading";
import { AuthContext } from "../context/Auth/AuthContext";

export const RoutePrivate = ({ children }) => {
  const { isAuth, loading } = useContext(AuthContext);

  if (loading) {
    return <BackdropLoading />;
  }

  if (!isAuth) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <LoggedInLayout>{children}</LoggedInLayout>;
};
