import { React, useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCheckCircle, FaCopy } from "react-icons/fa";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { SocketContext } from "../../../context/Socket/SocketContext";
import { useDate } from "../../../hooks/useDate";
import { SuccessContent, Total } from "./style";
function CheckoutSuccess(props) {
  const { pix } = props;
  const [pixString] = useState(pix.qrcode.qrcode);
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const socketManager = useContext(SocketContext);

  const { dateToClient } = useDate();

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);
    socket.on(`company-${companyId}-payment`, (data) => {
      if (data.action === "CONCLUIDA") {
        toast.success(
          `Sua licença foi renovada até ${dateToClient(data.company.dueDate)}!`
        );
        setTimeout(() => {
          navigate("/");
        }, 4000);
      }
    });
  }, [history, socketManager]);

  const handleCopyQR = () => {
    setTimeout(() => {
      setCopied(false);
    }, 1 * 1000);
    setCopied(true);
  };

  return (
    <React.Fragment>
      <Total>
        <span>TOTAL</span>
        <strong>
          R$
          {pix.valor.original.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
          })}
        </strong>
      </Total>
      <SuccessContent>
        <QRCode value={pixString} />
        <CopyToClipboard text={pixString} onCopy={handleCopyQR}>
          <button className="copy-button" type="button">
            {copied ? (
              <>
                <span>Copiado</span>
                <FaCheckCircle size={18} />
              </>
            ) : (
              <>
                <span>Copiar código QR</span>
                <FaCopy size={18} />
              </>
            )}
          </button>
        </CopyToClipboard>
        <span>
          Para finalizar, basta realizar o pagamento escaneando ou colando o
          código Pix acima :)
        </span>
      </SuccessContent>
    </React.Fragment>
  );
}

export default CheckoutSuccess;
