import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TicketsContext = createContext();

const TicketsContextProvider = ({ children }) => {
  const [currentTicket, setCurrentTicket] = useState({ id: null, code: null });
  const navigate = useNavigate();

  useEffect(() => {
    if (currentTicket.id !== null) {
      navigate(`/tickets/${currentTicket.uuid}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicket]);

  return (
    <TicketsContext.Provider value={{ currentTicket, setCurrentTicket }}>
      {children}
    </TicketsContext.Provider>
  );
};

export { TicketsContext, TicketsContextProvider };
