import { Button } from "@material-ui/core";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TicketsContext } from "../../context/Tickets/TicketsContext";

export function MilvusTicket() {
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [ticket, setTicket] = useState(null);
  const navigate = useNavigate();

  const { setCurrentTicket } = useContext(TicketsContext);

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        useIntegration: status === "closed" ? false : ticket.useIntegration,
        promptId: status === "closed" ? false : ticket.promptId,
        integrationId: status === "closed" ? false : ticket.integrationId,
      });

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
      } else {
        setCurrentTicket({ id: null, code: null });
        navigate("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };
  return (
    <>
      <a
        href="https://releaseapp.milvus.com.br/tickets/details/23810750"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          // onClick={() => setOpenPopup(true)}
        >
          Abrir ticket
        </Button>
      </a>
      {/* <PopupMilvusTicket open={openPopup} onClose={() => setOpenPopup(false)} /> */}
    </>
  );
}
