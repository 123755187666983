import React, { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { nomeEmpresa, versionSystem } from "../../../package.json";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

const Copyright = () => {
  return (
    <Typography
      variant="body2"
      color="primary"
      align="center"
      className="text-white"
    >
      {nomeEmpresa}
      <sup>©</sup>
      {new Date().getFullYear()} - v{versionSystem}
    </Typography>
  );
};

export const useStylesLogin = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    background: "linear-gradient(to bottom, #1A5CE2 , #1A5CE2 , #0c41aa)", //cor de fundo
    //backgroundImage: `url(${fundofoto})`, // Substituir o background no assets
    backgroundColor: theme.palette.primary.main,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    backgroundColor: theme.palette.login, //DARK MODE PLW DESIGN//
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "55px 30px",
    borderRadius: "12.5px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  powered: {
    color: "white",
  },
}));

const Login = () => {
  const classes = useStylesLogin();

  const [user, setUser] = useState({ email: "", password: "" });

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className="logo-etech w-[70%]" />

          <form className={classes.form} noValidate onSubmit={handlSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18n.t("login.form.email")}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("login.form.password")}
              type="password"
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={`h-12 ${classes.submit}`}
            >
              {i18n.t("login.buttons.submit")}
            </Button>{" "}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link
                  href="#"
                  variant="body2"
                  component={RouterLink}
                  to="/forgetpsw"
                >
                  {i18n.t("Esqueci minha senha")}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  href="#"
                  variant="body2"
                  component={RouterLink}
                  to="/signup"
                >
                  {i18n.t("login.buttons.register")}
                </Link>
              </Grid>
            </Grid>
          </form>
          {/* <IconButton
            color="primary"
            onClick={() =>
              openInNewTab(
                `https://wa.me/${process.env.REACT_APP_NUMBER_SUPPORT}`
              )
            }
          >
            <WhatsAppIcon style={{ color: "#25D366" }} />
          </IconButton>
          <Typography variant="caption" className={classes.supportText}>
            <b>Fale com suporte</b>
          </Typography> */}
        </div>
        <Box mt={8}>
          <Copyright className="text-white" />
        </Box>
      </Container>
    </div>
  );
};

export default Login;
