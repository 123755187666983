import React, { useEffect, useState } from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { SocketContext, SocketManager } from "./context/Socket/SocketContext";

import { useMediaQuery } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ColorModeContext from "./layout/themeContext";

import "./index.css";
import RoutesApp from "./routes";
const queryClient = new QueryClient();

const App = () => {
  const [locale, setLocale] = useState();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const preferredTheme = window.localStorage.getItem("preferredTheme");
  const [mode, setMode] = useState(
    preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light"
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: mode === "light" ? "#aaa" : "#202733",
          borderRadius: "8px",
        },
      },
      scrollbarStylesSoft: {
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
        },
      },
      palette: {
        type: mode,
        primary: { main: mode === "light" ? "#1A5CE2" : "#1A5CE2" },
        textPrimary: mode === "light" ? "#1A5CE2" : "#FFFFFF",
        borderPrimary: mode === "light" ? "#1A5CE2" : "#FFFFFF",
        dark: {
          main: mode === "light" ? "#202733" : "#F3F3F3",
        },
        light: { main: mode === "light" ? "#F3F3F3" : "#202733" },
        tabHeaderBackground: mode === "light" ? "#EEE" : "#202733",
        optionsBackground: mode === "light" ? "#fafafa" : "#202733",
        options: mode === "light" ? "#fafafa" : "#202733",
        fontecor: mode === "light" ? "#128c7e" : "#fff",
        fancyBackground: mode === "light" ? "#fafafa" : "#202733",
        bordabox: mode === "light" ? "#eee" : "#202733",
        newmessagebox: mode === "light" ? "#eee" : "#202733",
        inputdigita: mode === "light" ? "#fff" : "#202733",
        contactdrawer: mode === "light" ? "#fff" : "#202733",
        announcements: mode === "light" ? "#ededed" : "#202733",
        login: mode === "light" ? "#fff" : "#202733",
        announcementspopover: mode === "light" ? "#fff" : "#202733",
        chatlist: mode === "light" ? "#eee" : "#202733",
        boxlist: mode === "light" ? "#ededed" : "#202733",
        boxchatlist: mode === "light" ? "#ededed" : "#202733",
        total: mode === "light" ? "#fff" : "#202733",
        messageIcons: mode === "light" ? "grey" : "#F3F3F3",
        inputBackground: mode === "light" ? "#FFFFFF" : "#202733",
        barraSuperior:
          mode === "light"
            ? "linear-gradient(to right, #1A5CE2, #1A5CE2 , #1A5CE2)"
            : "#202733",
        boxticket: mode === "light" ? "#EEE" : "#202733",
        campaigntab: mode === "light" ? "#ededed" : "#202733",
        mediainput: mode === "light" ? "#ededed" : "#1A5CE2",
      },
      mode,
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("preferredTheme", mode);
    if (
      localStorage.preferredTheme === "dark" ||
      (!("preferredTheme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [mode]);

  return (
    <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SocketContext.Provider value={SocketManager}>
            <RoutesApp />
          </SocketContext.Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
