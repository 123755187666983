import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// ReactDOM.render(
// 	<CssBaseline>
// 		<App />
// 	</CssBaseline>,
// 	document.getElementById("root")
// );

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <App />
    </CssBaseline>
  </React.StrictMode>,

  document.getElementById("root")
);
