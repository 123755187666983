import { People } from "@material-ui/icons";
import React from "react";

// Função para processar o conteúdo do vCard
const parseVCard = (vCard) => {
  const lines = vCard.split("\n");
  console.log(vCard);

  const data = {};

  lines.forEach((line) => {
    if (line.startsWith("FN:")) {
      data.fullName = line.split(":")[1];
    } else if (line.includes("TEL")) {
      const dataPhone = line.split("waid=")[1].split(":");
      data.phone = dataPhone[1];
      data.waid = dataPhone[0];
    } else if (line.startsWith("X-WA-BIZ-NAME:")) {
      data.businessName = line.split(":")[1];
    }
  });

  return data;
};

export const VCardComponent = ({ vCardText }) => {
  const contactInfo = parseVCard(vCardText);

  return (
    <div className="">
      <header className="flex gap-2 items-center my-2 mx-2">
        <div className="rounded-full w-12 h-12 bg-zinc-200 text-zinc-700 flex items-center justify-center">
          <People />
        </div>
        <div>
          <p>
            <strong>{contactInfo.fullName}</strong>
          </p>
          <p>{contactInfo.phone ?? contactInfo.waid}</p>
        </div>
      </header>
      <footer></footer>
    </div>
  );
};
