import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthProvider } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Announcements from "../pages/Annoucements";
import CampaignReport from "../pages/CampaignReport";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import Chat from "../pages/Chat";
import Companies from "../pages/Companies/";
import Connections from "../pages/Connections";
import ContactListItems from "../pages/ContactListItems";
import ContactLists from "../pages/ContactLists";
import Contacts from "../pages/Contacts";
import Dashboard from "../pages/Dashboard";
import Files from "../pages/Files";
import Financeiro from "../pages/Financeiro";
import Helps from "../pages/Helps";
import Kanban from "../pages/Kanban";
import Login from "../pages/Login";
import MessagesAPI from "../pages/MessagesAPI";
import Prompts from "../pages/Prompts";
import QueueIntegration from "../pages/QueueIntegration";
import Queues from "../pages/Queues";
import QuickMessages from "../pages/QuickMessages";
import Schedules from "../pages/Schedules";
import SettingsCustom from "../pages/SettingsCustom";
import Signup from "../pages/Signup";
import Subscription from "../pages/Subscription";
import Tags from "../pages/Tags";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import ToDoList from "../pages/ToDoList";
import Users from "../pages/Users";
import { RoutePrivate } from "./Route";
//import EmailScheduler from "../pages/EmailScheduler/"; //Email
//import EmailsAgendado from "../pages/EmailsAgendado/"; //Email
import ForgetPassword from "../pages/ForgetPassWord"; // Reset PassWd

const RoutesApp = () => {
  const [showCampaigns, setShowCampaigns] = useState(false);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <TicketsContextProvider>
          <WhatsAppsProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <RoutePrivate>
                    <Dashboard />
                  </RoutePrivate>
                }
              />
              <Route
                path="/tickets/:ticketId?"
                element={
                  <RoutePrivate>
                    <TicketResponsiveContainer />
                  </RoutePrivate>
                }
              />
              <Route
                path="/connections"
                element={
                  <RoutePrivate>
                    <Connections />
                  </RoutePrivate>
                }
              />
              <Route
                path="/quick-messages"
                element={
                  <RoutePrivate>
                    <QuickMessages />
                  </RoutePrivate>
                }
              />
              <Route
                path="/todolist"
                element={
                  <RoutePrivate>
                    <ToDoList />
                  </RoutePrivate>
                }
              />
              <Route
                path="/schedules"
                element={
                  <RoutePrivate>
                    <Schedules />
                  </RoutePrivate>
                }
              />
              <Route
                path="/tags"
                element={
                  <RoutePrivate>
                    <Tags />
                  </RoutePrivate>
                }
              />
              <Route
                path="/contacts"
                element={
                  <RoutePrivate>
                    <Contacts />
                  </RoutePrivate>
                }
              />
              <Route
                path="/helps"
                element={
                  <RoutePrivate>
                    <Helps />
                  </RoutePrivate>
                }
              />
              <Route
                path="/users"
                element={
                  <RoutePrivate>
                    <Users />
                  </RoutePrivate>
                }
              />
              <Route
                path="/files"
                element={
                  <RoutePrivate>
                    <Files />
                  </RoutePrivate>
                }
              />
              <Route
                path="/prompts"
                element={
                  <RoutePrivate>
                    <Prompts />
                  </RoutePrivate>
                }
              />
              <Route
                path="/queue-integration"
                element={
                  <RoutePrivate>
                    <QueueIntegration />
                  </RoutePrivate>
                }
              />
              <Route
                path="/messages-api"
                element={
                  <RoutePrivate>
                    <MessagesAPI />
                  </RoutePrivate>
                }
              />
              <Route
                path="/settings"
                element={
                  <RoutePrivate>
                    <SettingsCustom />
                  </RoutePrivate>
                }
              />
              <Route
                path="/kanban"
                element={
                  <RoutePrivate>
                    <Kanban />
                  </RoutePrivate>
                }
              />
              <Route
                path="/financeiro"
                element={
                  <RoutePrivate>
                    <Financeiro />
                  </RoutePrivate>
                }
              />
              <Route
                path="/queues"
                element={
                  <RoutePrivate>
                    <Queues />
                  </RoutePrivate>
                }
              />
              <Route
                path="/announcements"
                element={
                  <RoutePrivate>
                    <Announcements />
                  </RoutePrivate>
                }
              />
              <Route
                path="/subscription"
                element={
                  <RoutePrivate>
                    <Subscription />
                  </RoutePrivate>
                }
              />
              <Route
                path="/chats/:id?"
                element={
                  <RoutePrivate>
                    <Chat />
                  </RoutePrivate>
                }
              />
              {showCampaigns && (
                <>
                  <Route
                    path="/contact-lists"
                    element={
                      <RoutePrivate>
                        <ContactLists />
                      </RoutePrivate>
                    }
                  />
                  <Route
                    path="/contact-lists/:contactListId/contacts"
                    element={
                      <RoutePrivate>
                        <ContactListItems />
                      </RoutePrivate>
                    }
                  />
                  <Route
                    path="/campaigns"
                    element={
                      <RoutePrivate>
                        <Campaigns />
                      </RoutePrivate>
                    }
                  />
                  <Route
                    path="/campaign/:campaignId/report"
                    element={
                      <RoutePrivate>
                        <CampaignReport />
                      </RoutePrivate>
                    }
                  />
                  <Route
                    path="/campaigns-config"
                    element={
                      <RoutePrivate>
                        <CampaignsConfig />
                      </RoutePrivate>
                    }
                  />
                </>
              )}
            </Routes>
          </WhatsAppsProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgetpsw" element={<ForgetPassword />} />
            {/* Reset Password */}
            <Route path="/create-company" element={<Companies />} />
          </Routes>
          <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default RoutesApp;
